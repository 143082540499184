import { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useMisc } from '../MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../../services/cookies';

const FGMateriaisComposicaoContext = createContext();
export const useFGMateriaisComposicao = () => useContext(FGMateriaisComposicaoContext);

export const FGMateriaisComposicaoProvider = ({ children }) => {
    const { setShowLoading } = useMisc();
    const userLogin = CookiesService.getUserLogin();

    const [mainData, setMainData] = useState([]);    
    const [materialSelecionado, setMaterialSelecionado] = useState({});
    const [materialComposicao, setMaterialComposicao] = useState([]);
    const [showCad, setShowCad] = useState(false);    
    const [file, setFile] = useState(null);

    const [tiposFibra, setTiposFibra] = useState([]);
    const [fornecedoresPa, setFornecedoresPa] = useState([]);
    const [fornecedoresMp, setFornecedoresMp] = useState([]);


    const [modalFornecedorShow, setModalFornecedorShow] = useState(false);
    const [modalFornecedorConfirmShow, setModalFornecedorConfirmShow] = useState(false);
    const [newFornecedor, setNewFornecedor] = useState('');
    const [fornecedorType, setFornecedorType] = useState(''); // 'MP' or 'PA'
    const [inputFornecedorValue, setInputFornecedorValue] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    
    const [filterObj, setFilterObj] = useState({
        refLinx: '',
        descricao: ''        
    });

    const fetchData = async () => {
        try {
            const [fibraRes, fornecedoresPaRes, fornecedoresMpRes] = await Promise.all([
                api.get('FGMateriaisComposicao/tipoFibra'),
                api.get('FGMateriaisComposicao/fornecedores-pa'),
                api.get('FGMateriaisComposicao/fornecedores-mp')
            ]);

            setTiposFibra([{ IdTipoFibra: '', TipoFibra: 'Selecione' }, ...fibraRes.data]);
            setFornecedoresPa([{ IdFornecedor: '', Fornecedor: 'Selecione' }, ...fornecedoresPaRes.data]);
            setFornecedoresMp([{ IdFornecedor: '', Fornecedor: 'Selecione' }, ...fornecedoresMpRes.data]);
        } catch (e) {
            console.error('Erro ao carregar dados dos selects', e);
            toast.error('Erro ao carregar dados dos selects');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const handleShowCad = (item) => {
        resetStates();
        setShowCad(true);
    }

    const handleSearch = async () => {
        setShowLoading(true);        
        const params = { ...filterObj };
        await api.get('FGMateriaisComposicao/materiais-composicao-fetch-grid', { params })
          .then(res => {            
            setMainData(res.data);
            setShowLoading(false);
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }

    const generateChaveFg = () => {
        const { descricao, gramatura } = materialSelecionado;
        if(!descricao) return '';

        const fibraDescriptions = materialComposicao.map(comp => {
            const fibra = tiposFibra.find(f => f.IdTipoFibra == comp.IdTipoFibra);
            return `${fibra?.TipoFibra ? fibra?.TipoFibra : '' || ''}${comp.PercFibra ? comp.PercFibra : ''}`;
        }).join('_');
        return removeAccents(`${descricao}/${fibraDescriptions}/${ gramatura ? gramatura : ''}Gr`).toUpperCase();
    }

    const resetStates = () => {
        setMaterialSelecionado({});
        setMaterialComposicao([]);        
        setFile(null);
    }

    useEffect(() => {
        const chaveFg = generateChaveFg();
        setMaterialSelecionado(prev => ({ ...prev, chaveFg }));
    }, [materialSelecionado.descricao, materialSelecionado.gramatura, materialComposicao]);


    const handleSave = async () => {        
        const { descricao, gramatura, tipo, idFornecedorMp, idFornecedorPa, refLinx, chaveFg, chaveTipo, usuarioRegistro, usuarioAlteracao } = materialSelecionado;
    
        if (!descricao || !gramatura || !tipo) {
            toast.error('Todos os campos devem ser preenchidos');
            return;
        }

        if (!file) {
            toast.error('Todos os campos devem ser preenchidos');
            return;
        }
    
        if (!idFornecedorMp && !idFornecedorPa) {
            toast.error('Selecione um fornecedor');
            return;
        }
    
        const totalPercFibra = materialComposicao.reduce((total, item) => total + parseFloat(item.PercFibra), 0);
        if (totalPercFibra !== 100) {
            toast.error('A soma dos percentuais deve ser 100%');
            return;
        }
    
        const seenFibers = new Set();
        for (let i = 0; i < materialComposicao.length; i++) {
            const currentFibra = materialComposicao[i];            
            if (!currentFibra.IdTipoFibra) {
                toast.error('Selecione todos os tipos de fibra');
                return;
            }
            if (currentFibra.PercFibra < 1) {
                toast.error('O valor percentual da fibra deve ser maior que zero');
                return;
            }
            // Verificação de duplicidade
            if (seenFibers.has(currentFibra.IdTipoFibra)) {
                toast.error('O mesmo tipo de fibra não pode ser adicionado mais de uma vez');
                return;
            }
            seenFibers.add(currentFibra.IdTipoFibra);
            
            if (tiposFibra[currentFibra.IdTipoFibra].TipoFibra == "Outras Fibras" && parseFloat(currentFibra.PercFibra) > 15) {
                toast.error('Outras Fibras não pode ter percentual maior que 15%');
                return;
            }          
    
            // Verificação se PercFibra é maior que o registro anterior
            if (i > 0) {
                const previousFibra = materialComposicao[i - 1];
                if (parseFloat(currentFibra.PercFibra) > parseFloat(previousFibra.PercFibra)) {
                    toast.error('O percentual de uma fibra não pode ser superior ao da fibra anterior');
                    return;
                }
            }
        }
    
        if (materialComposicao.some(item => !item.IdTipoFibra)) {
            toast.error('Os tipos de fibra devem ser selecionados');
            return;
        }
    
        try {
            setShowLoading(true);
    
            const existingMaterial = await api.get('FGMateriaisComposicao/materiais-composicao-fetch-grid', {
                params: { ChaveFg: chaveFg }
            });
    
            if (existingMaterial.data.length > 0) {
                toast.error('Material já cadastrado anteriormente com a mesma ChaveFg');
                setShowLoading(false);
                return;
            }
    
            const formData = new FormData();
            formData.append('Descricao', removeAccents(descricao).toUpperCase());
            formData.append('Gramatura', gramatura);
            formData.append('Tipo', tipo);
            formData.append('IdFornecedorMp', idFornecedorMp ? idFornecedorMp : '');
            formData.append('IdFornecedorPa', idFornecedorPa ? idFornecedorPa : '');
            formData.append('RefLinx', refLinx ? refLinx :'');
            formData.append('ChaveFg', chaveFg);
            formData.append('ChaveTipo', chaveTipo);
            formData.append('UsuarioRegistro', userLogin);
            formData.append('UsuarioAlteracao', usuarioAlteracao);
            formData.append('listComposicao', JSON.stringify(materialComposicao));
            formData.append('file', file);
           
    
            const response = await api.post('FGMateriaisComposicao/materiais-composicao-processar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                toast.success('Material cadastrado com sucesso');
                handleSearch();
                setShowCad(false);
            } else {
                throw new Error('Erro ao cadastrar o material');
            }
        } catch (e) {
            toast.error('Erro ao cadastrar o material');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setShowLoading(false);
        }
    }
    

    const handleAddComposicao = () => {
        setMaterialComposicao([...materialComposicao, { IdTipoFibra: '', PercFibra:'' }]);
    }

    const handleRemoveComposicao = (index) => {
        const newComposicao = materialComposicao.filter((_, i) => i !== index);
        setMaterialComposicao(newComposicao);
    }

    const handleChangeComposicao = (index, key, value) => {
        const newComposicao = [...materialComposicao];
        newComposicao[index][key] = value;
        setMaterialComposicao(newComposicao);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMaterialSelecionado(prev => ({ ...prev, [name]: value }));
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }
    const handleShowPDF = async (fileName) => {      
        try {            
          const params = { nome: fileName  }
            const res = await api.get('/FGMateriaisComposicao/ler-arquivo/',{params} );
            if (res.data.statusCode && res.data.statusCode === 404) return toast.warning(res.data.message);
            window.open(res.data[0], '_blank');
        } catch (e) {
            toast.error('Ocorreu um erro ao baixar o arquivo.');
        }
    }

    const handleChangeTipo = (tipo) => {
        setMaterialSelecionado({
            ...materialSelecionado,
            tipo: materialSelecionado.tipo === tipo ? '' : tipo
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        let sortedData = [...mainData].sort((a, b) => {
            const aValue = a[key] ?? '';
            const bValue = b[key] ?? '';
            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setMainData(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'arrow_drop_up' : 'arrow_drop_down';
        }
        return 'unfold_more';
    };
    
    const removeAccents = (str) => {
        if(!str) return '';        
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    };
    
    const handleSaveUpperCase = () => {
        const materialUpper = {
            ...materialSelecionado,
            descricao: removeAccents(materialSelecionado.descricao.toUpperCase()),
        };
        setMaterialSelecionado(materialUpper);
        handleSave();
    };

    const GravarFornecedor = async () => {
        // Chame o backend para gravar o fornecedor       
        try {            
            const body = { fornecedor: newFornecedor, isMp: fornecedorType === 'Mp' ? 1 : 0, isPa: fornecedorType === 'Pa' ? 1 : 0, UsuarioRegistro: userLogin };
            const result  =  await api.post('/FGMateriaisComposicao/upsert-fornecedor', {body});
            const IdFornecedor = result.data.IdFornecedor;            
            if (fornecedorType === 'Mp') {     
                setFornecedoresMp(prev => [...prev, { IdFornecedor: IdFornecedor, Fornecedor: newFornecedor.toUpperCase() }]);          
                setMaterialSelecionado(prev => ({ ...prev, idFornecedorMp: IdFornecedor }));
              } else if (fornecedorType === 'Pa') {        
                setFornecedoresPa(prev => [...prev, { IdFornecedor: IdFornecedor, Fornecedor: newFornecedor.toUpperCase() }]);        
                setMaterialSelecionado(prev => ({ ...prev, idFornecedorPa: IdFornecedor }));
              }
            toast.success('Fornecedor cadastrado com sucesso');
          } catch (e) {
              toast.error('Ocorreu ao gravar o fornecedor.');
          }

        setModalFornecedorConfirmShow(false);
        //fetchData(); 
    };

    const handleFornecedorCreate = (inputFornecedorValue, type) => {
        const fornecedorList = type === 'Mp' ? fornecedoresMp : fornecedoresPa;
        const found = fornecedorList.some(fornecedor => fornecedor.Fornecedor.toLowerCase().includes(inputFornecedorValue.toLowerCase()));
        if (!found) {
            setNewFornecedor(inputFornecedorValue.toUpperCase());
            setFornecedorType(type);
            setModalFornecedorShow(true);
        }
      };
    
      const handleFornecedorConfirm = () => {
        setModalFornecedorConfirmShow(true);
      };
    
      const handleFornecedorSave = async () => {           
        await GravarFornecedor();        
        setModalFornecedorConfirmShow(false);
        setModalFornecedorShow(false);
      };

    return (
        <FGMateriaisComposicaoContext.Provider
          value={{ 
              setShowLoading, 
              mainData, 
              setMainData, 
              filterObj, 
              setFilterObj, 
              handleSearch, 
              handleShowCad, 
              materialSelecionado, 
              setMaterialSelecionado, 
              materialComposicao, 
              setMaterialComposicao, 
              showCad, 
              setShowCad, 
              handleSave,
              file,
              setFile,
              tiposFibra,
              fornecedoresPa,
              fornecedoresMp,
              handleAddComposicao,
              handleRemoveComposicao,
              handleChangeComposicao,
              handleChange,
              handleFileChange,
              handleShowPDF,
              handleChangeTipo,
              handleSort,
              getSortIcon,
              removeAccents,
              handleSaveUpperCase,              
              modalFornecedorShow, setModalFornecedorShow,
              modalFornecedorConfirmShow, setModalFornecedorConfirmShow,
              newFornecedor, setNewFornecedor,
              fornecedorType, setFornecedorType,
              inputFornecedorValue, setInputFornecedorValue,
              fetchData,              
              handleFornecedorCreate,
              handleFornecedorConfirm,
              handleFornecedorSave,

          }}
        >
          {children}
        </FGMateriaisComposicaoContext.Provider>
    );
}
