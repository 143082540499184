import { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../../services/api';
import { useMisc } from '../MiscContext';
import { toast } from 'react-toastify';
import CookiesService from '../../services/cookies';

const FGTesteDeBaseContext = createContext();
export const useFGTesteDeBase = () => useContext(FGTesteDeBaseContext);

export const FGTesteDeBaseProvider = ({ children }) => {
    const { setShowLoading } = useMisc();
    const userLogin = CookiesService.getUserLogin();

    const [mainData, setMainData] = useState([]);    
    const [mainDataGrid, setMainDataGrid] = useState([]);    
    const [materialSelecionado, setMaterialSelecionado] = useState({});
    const [materialComposicao, setMaterialComposicao] = useState([]);
    const [showCad, setShowCad] = useState(false);   
    const [showConfirmacao, setShowConfirmacao] = useState(false);    
    const [showConfirmacaoNovaFicha, setShowConfirmacaoNovaFicha] = useState(false);
    const [showConfirmacaoNovaAmostra, setShowConfirmacaoNovaAmostra] = useState(false);
    const [showConfirmacaoRecebimento, setShowConfirmacaoRecebimento] = useState(false);    
    const [file, setFile] = useState(null);
    const [testeSelecionado, setTesteSelecionado] = useState({});

    const [tiposFibra, setTiposFibra] = useState([]);
    const [fornecedoresPa, setFornecedoresPa] = useState([]);
    const [fornecedoresMp, setFornecedoresMp] = useState([]);

    //RESULTADO DOS TESTES - PROCESSAR
    const [listTestes, setListTestes] = useState([]);
    const [listIndices, setListIndices] = useState([]);
    const [agendamentos, setAgendamentos] = useState(['FICHA TÉCNICA','GRAMATURA','LARGURA']);
    const [informacoesTeste, setInformacoesTeste] = useState({});
    const [observacao, setObservacao] = useState('');
    const [observacaoFinal, setObservacaoFinal] = useState('');

    //editar material
    const [showCadMaterial, setShowCadMaterial] = useState(false);   
    const [isLoading, setIsLoading] = useState(false);
    const [ materialAtivo, setMaterialAtivo] = useState({});
    const [ materialComposicaoAtivo, setMaterialComposicaoAtivo] = useState([]);

    //RECUSA DO RECEBIMENTO
    const [showMotivoRecusaModal, setShowMotivoRecusaModal] = useState(false);
    const [motivoRecusa, setMotivoRecusa] = useState('');    
    
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    
    const [filterObj, setFilterObj] = useState({
        refLinx: '',
        descricao: ''        
    });

    const [filterObjGrid, setFilterObjGrid] = useState({
        refLinx: '',
        descricao: ''        
    });

    const handleShowCad = (item) => {
        resetStates();
        setShowCad(true);
    }

    const handleShowCadTeste = (item) => {
        setTesteSelecionado(item);
        if(item.IdStatus == 66){            
            toast.warning('O recebimento deste teste foi reprovado.');
            return false;
        }
        if(item.IdStatus == 65){
            setShowConfirmacaoRecebimento(true);
        }else {
            setShowCad(true);
        }
        
    }

    const handleSearch = async () => {
        setMaterialSelecionado(null);
        if(!filterObj.descricao){
            toast.warning('Preencha a descrição do material');
            return;
        }
        setShowLoading(true);        
        const params = { ...filterObj };
        await api.get('FGTesteDeBase/materiais-disponiveis-fetch-grid', { params })
          .then(res => {            
            setMainData(res.data);
            setShowLoading(false);
            if(res.data.length == 0){
                toast.warning('Nenhum teste encontrado com os parâmetros informados');
            }
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }

    const handleSearchGrid = async () => {       
       
        setShowLoading(true);        
        const params = { ...filterObjGrid };
        await api.get('FGTesteDeBase/teste-de-base-fetch-grid', { params })
          .then(res => {                 
            setMainDataGrid(res.data);
            setShowLoading(false);
            if(res.data.length == 0){
                toast.warning('Nenhum teste encontrado com os parâmetros informados');
            }
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }
    

    const btSearchGrid = async () => {
        if(!filterObjGrid.descricao && !filterObjGrid.IdTesteBase && !filterObjGrid.idStatus){
            toast.warning('Preencha a Descrição do Material ou Nº da Requisição');
            return;
        }

        handleSearchGrid();
    }

    const btSearchGridPendentes = async () => {            
            if(!filterObjGrid.descricao && !filterObjGrid.IdTesteBase && !filterObjGrid.idStatus){
                toast.warning('Preencha a Descrição do Material ou Nº da Requisição');
                return;
            }
        handleSearchGridPendentes();
    }

    const resetStates = () => {
        setShowConfirmacao(false);
        setFilterObj({});
        setMainData([]);
        setMaterialSelecionado(null);
        setMaterialComposicao([]);        
        setFile(null);
    }

    const resetStatesProcessarTeste = () =>{
        setAgendamentos(['FICHA TÉCNICA','GRAMATURA','LARGURA']);
        setInformacoesTeste({});
        setTesteSelecionado(null);
        setObservacao(null);
        setObservacaoFinal(null);
    }
    
   
    const handleSave = async () => {        
       
        if(!materialSelecionado.IdMaterial){
            toast.error('Selecione um Material');
            setShowLoading(false);
            return;
        }

        try {
            setShowLoading(true);

            const existingMaterial = await api.get('FGTesteDeBase/teste-de-base-fetch-grid', {
                params: { ChaveFg: materialSelecionado.ChaveFg, JaExiste: true }
            });            
    
            if (existingMaterial.data.length > 0) {
                toast.error('Teste já solicitado anteriormente com a mesma ChaveFg');
                setShowLoading(false);
                return;
            }

            const response = await api.post('FGTesteDeBase/teste-de-base-solicitar', { UsuarioRegistro: userLogin,...materialSelecionado });
    
            if (response.status === 200) {
                toast.success('Teste de Base solicitado com sucesso');
                setShowConfirmacao(false);
                handleSearchGrid();
                setShowCad(false);                
            } else {
                throw new Error('Erro ao solicitar  o teste');
            }
        } catch (e) {
            toast.error('Erro ao solicitar  o teste');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setShowLoading(false);
        }
    }
    

    const handleAddComposicao = () => {
        setMaterialComposicaoAtivo([...materialComposicaoAtivo, { IdTipoFibra: '', PercFibra:'' }]);
    }

    const handleRemoveComposicao = (index) => {
        const newComposicao = materialComposicaoAtivo.filter((_, i) => i !== index);
        setMaterialComposicaoAtivo(newComposicao);
    }

    const handleChangeComposicao = (index, key, value) => {
        const newComposicao = [...materialComposicaoAtivo];
        newComposicao[index][key] = value;
        setMaterialComposicaoAtivo(newComposicao);
    }

    const handleChangeMaterialAtivo = (e) => {        
        const { name, value } = e.target;
        setMaterialAtivo(prev => ({ ...prev, [name]: value }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMaterialSelecionado(prev => ({ ...prev, [name]: value }));
    }

    const handleFileChange = (e) => {        
        setFile(e.target.files[0]);
    }
    const handleShowPDF = async (fileName) => {      
        try {            
          const params = { nome: fileName  }
            const res = await api.get('/FGTesteDeBase/ler-arquivo/',{params} );
            if (res.data.IdStatusTesteCode && res.data.IdStatusTesteCode === 404) return toast.warning(res.data.message);
            window.open(res.data[0], '_blank');
        } catch (e) {
            toast.error('Ocorreu um erro ao baixar o arquivo.');
        }
    }

    const handleChangeTipo = (tipo) => {
        setMaterialSelecionado({
            ...materialSelecionado,
            tipo: materialSelecionado.tipo === tipo ? '' : tipo
        });
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        let sortedData = [...mainData].sort((a, b) => {
            const aValue = a[key] ?? '';
            const bValue = b[key] ?? '';
            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setMainData(sortedData);
    };

    const handleSortGrid = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        let sortedData = [...mainDataGrid].sort((a, b) => {
            const aValue = a[key] ?? '';
            const bValue = b[key] ?? '';
            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setMainDataGrid(sortedData);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? 'arrow_drop_up' : 'arrow_drop_down';
        }
        return 'unfold_more';
    };
    
    const removeAccents = (str) => {
        if(!str) return '';        
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
    };
    
    const handleSaveUpperCase = () => {
        const materialUpper = {
            ...materialSelecionado,
            descricao: removeAccents(materialSelecionado.descricao.toUpperCase()),
        };
        setMaterialSelecionado(materialUpper);
        handleSave();
    };

    const handleListTestes = async () => {              
        setShowLoading(true);        
        const params = { IdTesteBase: testeSelecionado.IdTesteBase };
        await api.get('FGTesteDeBase/teste-de-base-list', { params })
          .then(res => {            
            setListTestes(res.data);            
            setShowLoading(false);
            
            if(res.data.length == 0){
                toast.warning('Nenhum teste encontrado com os parâmetros informados');
            }
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    }

    const handleListIndices = async () => {               
         setShowLoading(true);        
         const params = { IdTesteBase: testeSelecionado.IdTesteBase };
         await api.get('FGTesteDeBase/teste-de-base-indices', { params })
           .then(res => {            
             setListIndices(res.data);             
             setShowLoading(false);
             if(res.data.length == 0){
                 toast.warning('Nenhum indice encontrado com os parâmetros informados');
             }
           }).catch(e => {
             toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
             console.error(`Erro na requisição - ${e}`);
             setShowLoading(false);
           });
     }
     

     const handleValidaResultado = () => {
        for (const teste of agendamentos) {            
            const informacao = informacoesTeste[teste];            
    
            if (!informacao) {
                toast.warning(`Preencha as informações do teste de ${teste} `);
                return false; 
            }
    
            const descricaoTeste = listTestes.find(t => t.DescricaoTeste === teste);
            if (!descricaoTeste) {
                toast.warning(`Preencha as informações do teste de ${teste} `);
                return false; 
            }            
            const tipoTeste = descricaoTeste.DescricaoTeste;            
            if (tipoTeste === 'GRAMATURA') {              
                if (
                    informacao.IdStatusTeste == null || 
                    informacao.Gramatura == null || 
                    informacao.Gramatura === '' || 
                    isNaN(Number(informacao.Gramatura)) || 
                    informacao.GramaturaFornecedor == null || 
                    informacao.GramaturaFornecedor === '' || 
                    isNaN(Number(informacao.GramaturaFornecedor))
                ) {
                    toast.warning(`Preencha as informações do teste de ${tipoTeste} `);                    
                    return false;
                }
                const difGramatura = Math.abs((informacao.Gramatura - informacao.GramaturaFornecedor) / informacao.GramaturaFornecedor * 100);
                if (difGramatura > 10 && !informacao.Excecao && informacao.IdStatusTeste != 8) {
                    /* eslint-disable no-restricted-globals */
                    const excecaoCheckbox = confirm(`A diferença de Gramatura ultrapassa 10%. Deseja marcar este teste de ${teste} como exceção?`);                    
                    /* eslint-enable no-restricted-globals */
                    informacao.Excecao = excecaoCheckbox;

                    if (!excecaoCheckbox) {
                        toast.warning(`Marque a exceção ou revise as informações do teste de ${teste}`);
                        return false;
                    }                    
                }
            } else if (tipoTeste === 'LARGURA') {
                if (
                    informacao.IdStatusTeste == null || 
                    informacao.Largura == null || 
                    informacao.Largura === '' || 
                    isNaN(Number(informacao.Largura)) || 
                    informacao.LarguraFornecedor == null || 
                    informacao.LarguraFornecedor === '' || 
                    isNaN(Number(informacao.LarguraFornecedor))
                ) {
                    toast.warning(`Preencha as informações do teste de ${teste} `);
                    return false;
                }
                const difLargura = Math.abs((informacao.Largura - informacao.LarguraFornecedor) / informacao.LarguraFornecedor * 100);
                if (difLargura > 3 && !informacao.ExcecaoLargura & informacao.IdStatusTeste != 8) {
                    /* eslint-disable no-restricted-globals */
                    const excecaoCheckbox = confirm(`A diferença de Largura ultrapassa 3%. Deseja marcar este teste de ${teste} como exceção?`);
                    /* eslint-enable no-restricted-globals */
                    informacao.ExcecaoLargura = excecaoCheckbox;

                    if (!excecaoCheckbox) {
                        toast.warning(`Marque a exceção ou revise as informações do teste de ${teste}`);
                        return false;
                    }
                }             
            } else {
                const indicesFiltrados = listIndices.filter(indice => indice.DescricaoTeste === teste);                

                const indicesAprovadoReprovado = indicesFiltrados.map(indice => indice.Indice);
                const possuiAprovadoOuReprovado = indicesAprovadoReprovado.includes('APROVADO') || indicesAprovadoReprovado.includes('REPROVADO');

                if (possuiAprovadoOuReprovado) {
                    if (informacao.IdStatusTeste == null) {
                        toast.warning(`Preencha o status do teste de ${teste}`);
                        return false;
                    }
                    
                    if (informacao.IdStatusTeste == 4 && informacao.IdIndice != 'APROVADO' && informacao.IdIndice != null && informacao.IdIndice != '' ) {
                        toast.warning(`o status do teste de ${teste} não pode ser Aprovado e seu índice ${informacao.IdIndice}`);
                        return false;
                    }
                    if (informacao.IdStatusTeste == 8 && informacao.IdIndice == 'APROVADO' && informacao.IdIndice != null && informacao.IdIndice != '') {
                        toast.warning(`o status do teste de ${teste} não pode ser Reprovado e seu índice ${informacao.IdIndice}`);
                        return false;
                    }
                } else {
                    if (informacao.IdStatusTeste == null || (informacao.IdIndice == null && indicesFiltrados.length > 0)) {
                        toast.warning(`Preencha as informações do teste de ${teste}`);
                        return false;
                    }
                }                
                
            }
        }
    
        setShowConfirmacao(true);
    };

     const handleSaveAnalise = async () => {                     

        const objTeste ={
            UsuarioRegistro: userLogin,
            teste: testeSelecionado,
            listTestes: informacoesTeste,
            Observacao : observacao,
            ObservacaoFinal: observacaoFinal
        }        
        

        try {
            setShowLoading(true);            
            
            const response = await api.post('FGTesteDeBase/teste-de-base-processar-resultado', objTeste);

            if (response.status === 200) {
                toast.success('Resultado do Teste de Base enviado com sucesso');
                setShowConfirmacao(false);
                handleSearchGridPendentes()
                setShowCad(false);       
                resetStatesProcessarTeste();
            } else {
                throw new Error('Erro ao enviar o resultado 1');
            }
        } catch (e) {
            toast.error('Erro ao enviar o resultado 2');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setShowLoading(false);
        }
    }

    const handleSearchGridPendentes = async () => {       
       
        setShowLoading(true);        
        const params = { ...filterObjGrid };
        await api.get('FGTesteDeBase/teste-de-base-pendentes-fetch-grid', { params })
        .then(res => {            
            setMainDataGrid(res.data);
            setShowLoading(false);
            if(res.data.length == 0){
                toast.warning('Nenhum teste encontrado com os parâmetros informados');
            }
          }).catch(e => {
            toast.error('Ocorreu um erro ao carregar os dados da tela, por favor cheque sua internet e/ou entre contato com o suporte');
            console.error(`Erro na requisição - ${e}`);
            setShowLoading(false);
          });
    };

    const handleShowCadMaterial = (item) => {
        resetStates();            
        fetchMaterialInfo(item?.IdMaterial)
        setMaterialSelecionado(item);
        setShowCadMaterial(true);
    }

    const fetchMaterialInfo = async (idMaterial) => {
        setIsLoading(true);
        const params = { idMaterial: idMaterial };
        try {
            const response = await api.get(`FGMateriaisComposicao/material-composicao`, { params });

            const [fibraRes, fornecedoresPaRes, fornecedoresMpRes] = await Promise.all([
                api.get('FGMateriaisComposicao/tipoFibra'),
                api.get('FGMateriaisComposicao/fornecedores-pa'),
                api.get('FGMateriaisComposicao/fornecedores-mp')
            ]);

            setTiposFibra([{ IdTipoFibra: '', TipoFibra: 'Selecione' }, ...fibraRes.data]);
            setFornecedoresPa([{ IdFornecedor: '', Fornecedor: 'Selecione' }, ...fornecedoresPaRes.data]);
            setFornecedoresMp([{ IdFornecedor: '', Fornecedor: 'Selecione' }, ...fornecedoresMpRes.data]);
            setMaterialAtivo(response.data.material);
            setMaterialComposicaoAtivo(response.data.composicao);
        } catch (error) {
            console.error("Erro ao carregar as informações do material:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveUpdateMaterial = async (status) => {

        if (!materialAtivo.IdMaterial || !materialAtivo.Gramatura) {
            toast.error('Todos os campos devem ser preenchidos');
            return;
        }

        if (!file && status == 'nova-ficha') {
            toast.error('Você deve anexar uma nova ficha técnica');
            return;
        }
    
    
        const totalPercFibra = materialComposicaoAtivo.reduce((total, item) => total + parseFloat(item.PercFibra), 0);
        if (totalPercFibra !== 100) {
            toast.error('A soma dos percentuais deve ser 100%');
            return;
        }
    
        const seenFibers = new Set();
        for (let i = 0; i < materialComposicaoAtivo.length; i++) {
            const currentFibra = materialComposicaoAtivo[i];            
            if (!currentFibra.IdTipoFibra) {
                toast.error('Selecione todos os tipos de fibra');
                return;
            }
            if (currentFibra.PercFibra < 1) {
                toast.error('O valor percentual da fibra deve ser maior que zero');
                return;
            }
            // Verificação de duplicidade
            if (seenFibers.has(currentFibra.IdTipoFibra)) {
                toast.error('O mesmo tipo de fibra não pode ser adicionado mais de uma vez');
                return;
            }
            seenFibers.add(currentFibra.IdTipoFibra);
            
            if (tiposFibra[currentFibra.IdTipoFibra].TipoFibra == "Outras Fibras" && parseFloat(currentFibra.PercFibra) > 15) {
                toast.error('Outras Fibras não pode ter percentual maior que 15%');
                return;
            }          
    
            // Verificação se PercFibra é maior que o registro anterior
            if (i > 0) {
                const previousFibra = materialComposicaoAtivo[i - 1];
                if (parseFloat(currentFibra.PercFibra) > parseFloat(previousFibra.PercFibra)) {
                    toast.error('O percentual de uma fibra não pode ser superior ao da fibra anterior');
                    return;
                }
            }
        }
    
        if (materialComposicaoAtivo.some(item => !item.IdTipoFibra)) {
            toast.error('Os tipos de fibra devem ser selecionados');
            return;
        }
    
        try {
            setShowLoading(true);

            const formData = new FormData();            
            formData.append('IdMaterial', materialAtivo.IdMaterial);
            formData.append('Descricao', materialAtivo.Descricao);
            formData.append('Gramatura', materialAtivo.Gramatura);
            formData.append('Tipo', materialAtivo.tipo);
            formData.append('IdFornecedorMp', materialAtivo.idFornecedorMp);
            formData.append('IdFornecedorPa', materialAtivo.idFornecedorPa);
            formData.append('RefLinx', materialAtivo.RefLinx);
            formData.append('ChaveFg', materialAtivo.ChaveFg);
            formData.append('ChaveTipo', materialAtivo.chaveTipo);
            formData.append('UsuarioRegistro', materialAtivo.usuarioRegistro);
            formData.append('UsuarioAlteracao', userLogin);
            formData.append('Arquivo', materialAtivo.Arquivo);
            formData.append('Status', status);
            formData.append('IdTesteBase', materialSelecionado.IdTesteBase);            
            formData.append('listComposicao', JSON.stringify(materialComposicaoAtivo));            
            if (file) {
                formData.append('file', file);
            }

            await api.post('FGMateriaisComposicao/material-composicao-atualizar', formData);

            if (status === 'nova-ficha') {
                toast.success('Ficha técnica enviada com sucesso!');                                 
            } else if (status === 'nova-amostra') {                
                toast.success('Nova amostra enviada com sucesso!');
            }
            setShowLoading(false);
            handleSearchGrid();
            setShowCadMaterial(false);    
            setShowConfirmacaoNovaFicha(false);
            setShowConfirmacaoNovaAmostra(false);
            setMaterialAtivo({});

        } catch (error) {
            console.error("Erro ao salvar as informações:", error);
            setShowLoading(false);
        }
    };

    const handleSaveRecebimento = async (IdStatus, motivoRecusa) => {                     
        
        const objTeste ={
            IdUsuarioRecebimento: userLogin,
            IdStatus: IdStatus,
            IdTesteBase: testeSelecionado.IdTesteBase,  
            ObservacaoRecusa: motivoRecusa,         
        }                

        try {
            setShowLoading(true);           
            
            const response = await api.post('FGTesteDeBase/alterar-recebimento', objTeste);

            if (response.status === 200) {
                toast.success('Recebimento atualizado com sucesso');
                setShowConfirmacaoRecebimento(false);
                handleSearchGridPendentes()
                setShowCad(false);       
                resetStatesProcessarTeste();
            } else {
                throw new Error('Erro ao atualizar o recebimento');
            }
        } catch (e) {
            toast.error('Erro ao atualizar o recebimento');
            console.error(`Erro na requisição - ${e}`);
        } finally {
            setShowLoading(false);
        }
    }

    const generateChaveFg = () => {
        const { Descricao, Gramatura } = materialAtivo;
        if(!Descricao) return '';

        const fibraDescriptions = materialComposicaoAtivo.map(comp => {
            const fibra = tiposFibra.find(f => f.IdTipoFibra == comp.IdTipoFibra);
            return `${fibra?.TipoFibra ? fibra?.TipoFibra : '' || ''}${comp.PercFibra ? comp.PercFibra : ''}`;
        }).join('_');
        return removeAccents(`${Descricao}/${fibraDescriptions}/${ Gramatura ? Gramatura : ''}Gr`).toUpperCase();
    }
    

    const handleReprovarRecebimento = () => {        
        setShowMotivoRecusaModal(true); 
    };

    const handleEnviarRecusa = () => {
        if (motivoRecusa.trim() === "") {            
            toast.warning('Por favor, informe o motivo da recusa.');
            return;
        }        
        handleSaveRecebimento(66, motivoRecusa);
        setShowMotivoRecusaModal(false);
        setShowConfirmacaoRecebimento(false);
    };

    useEffect(() => {
        const ChaveFg = generateChaveFg();
        setMaterialAtivo(prev => ({ ...prev, ChaveFg }));
    }, [materialAtivo.Descricao, materialAtivo.Gramatura, materialComposicaoAtivo]);


    
    return (
        <FGTesteDeBaseContext.Provider
          value={{ 
              setShowLoading, 
              mainData, 
              setMainData, 
              filterObj, 
              setFilterObj, 
              handleSearch, 
              handleShowCad, 
              materialSelecionado, 
              setMaterialSelecionado, 
              materialComposicao, 
              setMaterialComposicao, 
              showCad, 
              setShowCad, 
              handleSave,
              file,
              setFile,
              tiposFibra,
              fornecedoresPa,
              fornecedoresMp,
              handleAddComposicao,
              handleRemoveComposicao,
              handleChangeComposicao,
              handleChange,
              handleFileChange,
              handleShowPDF,
              handleChangeTipo,
              handleSort,
              getSortIcon,
              removeAccents,
              handleSaveUpperCase,
              handleSearchGrid,
              mainDataGrid,
              setFilterObjGrid,
              filterObjGrid,
              handleSortGrid,
              showConfirmacao, 
              setShowConfirmacao, 
              btSearchGrid,             
              handleShowCadTeste,
              setTesteSelecionado,
              testeSelecionado,
              handleListTestes,
              listTestes,
              handleListIndices,
              listIndices,
              handleSaveAnalise,
              agendamentos, 
              setAgendamentos,
              informacoesTeste, 
              setInformacoesTeste,
              observacao, 
              setObservacao,
              observacaoFinal, 
              setObservacaoFinal,
              handleValidaResultado,
              resetStatesProcessarTeste,
              handleSearchGridPendentes,
              btSearchGridPendentes,
              showCadMaterial, 
              setShowCadMaterial,
              handleShowCadMaterial,
              fetchMaterialInfo,
              isLoading, setIsLoading,
              materialAtivo, setMaterialAtivo,
              materialComposicaoAtivo, setMaterialComposicaoAtivo
              ,handleSaveUpdateMaterial
              ,handleChangeMaterialAtivo
              ,file
              ,showConfirmacaoNovaFicha
              ,setShowConfirmacaoNovaFicha
              ,showConfirmacaoNovaAmostra
              ,setShowConfirmacaoNovaAmostra
              ,showConfirmacaoRecebimento
              ,setShowConfirmacaoRecebimento
              ,handleSaveRecebimento
              ,showMotivoRecusaModal, setShowMotivoRecusaModal
              ,motivoRecusa, setMotivoRecusa
              ,handleReprovarRecebimento
              ,handleEnviarRecusa
          }}
        >
          {children}
        </FGTesteDeBaseContext.Provider>
    );
}
